// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import 'Colours.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Verdana", sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #212121;
}

header {
  width: 100%;
}

#banner-row {
  padding-left: 60px;
  padding-right: 60px;
  background-color: white;
  @media (max-width: 750px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  img {
    width: 60px;
    height: 60px;
  }
  #company-name {
    font-family: "Verdana",sans-serif;
    font-size: 22px;
    color: rgb(14, 47, 63);
  }
  #tag-line{
    font-family: "Verdana", sans-serif;
    font-size: 15px;
    color: rgb(14, 47, 63);
  }
}

#nav-row {
 padding-left: 60px;
 padding-right: 60px;
 @media (max-width:750px) {
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
}
 color: rgb(14, 47, 63);

 #nav-tel-icon, #nav-email-icon {
  display:none;
}
 @media (max-width: 750px) {
  #nav-tel, #nav-email {
    display: none;
  }
  #nav-tel-icon, #nav-email-icon {
    display:inline-block;
  }
 }
}

nav {
  display: flex;
  flex-direction: column;
  background-color: $light_pink;
  height: 40px;
  width: 100%;
  display: inline-block;
  font-family: "Verdana", sans-serif;
  a {
    cursor: pointer;
    text-decoration: none;
    // color: rgba(0, 0, 0, 0.5);
    color: rgb(14, 47, 63) !important;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    &:hover, &:focus {
      // color: rgba(0, 0, 0, 0.7);
      color: #050f15 !important;
      text-decoration: none;
    }
  }
}


#hero {
  background-image: url(./cherry-trees-learn-english-ceret.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100%;
 fill-opacity: 0.5;
 overflow-x: hidden;
 overflow-y: auto;
 perspective: 2px;
}

#about-me-section {
  background-color: #5b9431;
  font-size: 17px;
  img {
margin-top: -15px;
  }
}

.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.hero-section {
  height: 100vh;
  height: 700px;
  box-shadow:inset 0 0 0 2000px rgba(240,229,229, 0.5);
}

.parallax::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  background-size: 100%;
  z-index: -1;
}


.hero-section::after {
  background-image: url(./cherry-trees-learn-english-ceret.webp);
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: center;
}

.bg2::after {
  background-image: url(./cherry-trees-learn-english-ceret.webp);
}

.hero-contents {
  width: 940px;
  margin: 15% 10% 30px 10%;
  justify-self: start;
}

#hero-title {
  overflow-wrap: break-word;
  text-align: start;
  h1 {
    padding-left: 10px;
    color: rgb(14, 47, 63);
    line-height: 1.2em;
    font-size: 4.5rem; // Used to eb 88px
    font-family: "Verdana",sans-serif;
    font-weight: 400;
    max-width: 700px;
  }
}

#hero-key-benefits {
  line-height: 1.41em;
  font-size: 22px;
  font-family: "Verdana",sans-serif;
  color: rgb(104,34,79);
  font-weight: 500;
  ul {
    list-style: none;
    padding-left: 10px;
  }
}

#hero-cta {
  a {
    margin-left: 10px;
    color: white;
    background-color: rgb(104, 34, 79);
    border-radius: 0;
    font-weight: 600;
    &:hover {
      box-shadow:inset 0 0 0 2000px rgba(240,229,229, 0.5);
      border-color: rgba(240,229,229, 0.5);
    }
  }
}

section {
  .grid-content {
    display: grid;
    grid-template-columns: 100%;
  }
}

  #contact {
    h1 {
      color: #5b9431;
      font-weight: 600;
      font-size: 40px;
    }
    form {
      p {
        padding-left: 30px;
        padding-right: 30px;
      }
      .text-input {
        appearance: none;
        border: none;
        border-bottom: 1px solid rgb(14, 47, 63);
        color: rgb(14, 47, 63);
        width: 100%;
        &:focus {
          border: 1px solid rgb(14, 47, 63);
          outline: none;
        }
      }
      fieldset {
        text-align: start;
        legend, label {
          color: rgb(14, 47, 63);
          font-size: 15px;
        }
      }
      .submit-btn {
        background-color: rgb(14, 47, 63);
        color: white;
        width: 50%;
        border-radius: 0;
        font-weight: 500;
        font-size: 18px;
        margin-left: 30px;
        margin-right: 30px;
        padding: 10px;
        border:none;
        &:hover {
          background-color: #8a8b8c;
        }
        transition: background-color 0.4s ease 0s;
      }
    }
    .confirm-message {
      color: rgb(14, 47, 63);
    }

    button[disabled] {
      cursor: not-allowed;
    }
  }

.img-text-split-contents {
  .right-content {
    h1 {
      font-weight: 700;
    }
    p:first-of-type {
      font-weight: 700;
    }
    p {
      line-height: 30px;
    }
  }

}

#cta-after-about {
  background-color: #5b9431;
  padding: 20px 10px;
  .btn {
    border-radius: 0;
    font-weight: 500;
    background-color: white;
    box-shadow:2px 2.3px 8px rgba(0, 0, 0, 0.45);
    color: rgb(14, 47, 63);
    &:hover {
      background-color: #aeca9a;
    }
    transition: background-color 0.4s ease 0s;
  }

}

#course-location-section {
  background-color: #5b9431;
  h1 {
    font-weight: 700;
    margin: 30px;
  }
}

#course-location-section {
  .card {
    border: none;
  }
  .card-title {
color: #5b9431;
  }
  .card-text {
    color: rgb(14, 47, 63);
  }
}

.teaching-details-section {
  color:rgb(14, 47, 63);
  .card {
    border: none;
  }
  .card-title {
    color: #5b9431;
    font-weight: 600;
    font-size: 18px;
  }
  .card-text {
    font-size: 18px;
  }
  ul {
    padding-left: 1.3em;
    padding-right: 0;
    margin-left: 0.5em;
  }
  a {
    color:rgb(14, 47, 63);
    text-decoration: underline;
    &:hover {
      color:#020608;
    }
  }
}

.review-section {
  .review-box{
    background-color: #68224f;
    min-height: 100px;
    width: 80%;
    .feedback-comment {
      font-size: 1.3rem;
      font-weight: 600;
      padding: 20px 20px 10px 20px;
    }
    .feedback-from {
      font-size: 1.3rem;
      font-weight: 600;
      padding-left: 20px;
    }
    a {
      color: white;
      text-decoration: underline;
    }
  }
}

#map-section {
background-color: #f1f3f4;
color:rgb(14, 47, 63);
}

foooter {
  color: #0e2f3f;
  .top-footer {
    height: 100px;
  }
  .bottom-border {
    background-color: #f0e5e5;
    height: 40px;
    width: 100%;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: rgb(14, 47, 63) !important;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    &:hover, &:focus {
      color: #050f15 !important;
      text-decoration: none;
    }
  }
}